<template>

  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-row>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Recherche (Nom, pénom, email, n° commande...)"
                @keyup.native.enter="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0"
            >
              <flat-pickr
                v-model="rangeDate"
                placeholder="Date"
                class="form-control mr-1"
                :config="{ mode: 'range'}"
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0"
            >
              <v-select
                v-model="status"
                placeholder="Statut"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="statusOptions"
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0 ml-1"
            >
              <v-select
                v-model="shop"
                placeholder="Shop"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="Shop"
                :options="shopOptions"
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0 ml-1"
            >
              <v-select
                v-model="deliveryCountries"
                placeholder="Delevery country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="Delevery country"
                :options="deliveryCountriesOptions"
                :multiple="true"
                @input="setQuery"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip,
  BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      status: '',
      shop: '',
      dispatchType: '',
      logisticType: '',
      shipperType: '',
      rangeDate: null,
      searchQuery: '',
      deliveryCountries: [],
      statusOptions: ['En attente', 'Retour à créer', 'Envoyé au client', ' A rembourser', 'Hors délai', 'Remboursée'],
      dispatchOptions: [
        'Annulé',
        'Non Dispatché',
        'Dispatché',
      ],
      logisticOptions: [
        'Agediss',
        'CAR',
        'GommaGomma',
        'Flexilan',
        'Radi',
        'Futon',
        'Loupiote',
      ],
      shipperOptions: [
        'UPS',
        'BRT',
        'GLS',
        'Agediss',
        'Hermes',
        'B2C',
      ],
      shopOptions: [
        'FR',
        'IT',
        'ES',
        'DE',
        'PT',
        'EN',
        'NL',
        'BB',
      ],
      deliveryCountriesOptions: [
        'FR',
        'CH',
        'AT',
        'IE',
        'BE',
        'LU',
        'IT',
        'ES',
        'DE',
        'PT',
        'EN',
        'NL',
        'DK',
        'BB',
      ],
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  watch: {
    // rangeDate: {
    //   handler: 'setQuery',
    //   deep: true,
    // },
  },
  async mounted() {
    await this.get()
    if (this.$route.query?.name) {
      this.searchQuery = this.$route.query?.name
    }
    if (this.$route.query?.shop) {
      this.shop = this.$route.query?.shop?.toUpperCase()
    }
    if (this.$route.query?.status) {
      this.status = this.$route.query?.status
    }
    if (this.$route.query?.name) {
      this.searchQuery = this.$route.query?.name
    }
    if (this.$route.query?.created_at?.includes('to')) {
      this.rangeDate = this.$route.query?.created_at
    }
    this.setQuery()
  },
  methods: {
    ...mapActions('admin', ['get']),
    async setQuery() {
      const filter = {}
      const $and = []
      let query = {}
      if (this.searchQuery && this.searchQuery !== '') {
        query = { ...query, name: this.searchQuery }
        this.$router.replace({ query })
      }
      if (this.status && this.status !== '') {
        $and.push({ 'system.return.status': this.status })
        query = { ...query, status: this.status }
        this.$router.replace({ query })
      }
      if (this.shop && this.shop !== '') {
        $and.push({ shop: this.shop })
        query = { ...query, shop: this.shop }
        this.$router.replace({ query })
      }
      if (this.deliveryCountries && this.deliveryCountries.length) {
        // $and.push({ shop: { $in: this.shop } })
        $and.push({ 'order.shipping.country': { $in: this.deliveryCountries } })
        query = { ...query, 'order.shipping.country': this.deliveryCountries }
        this.$router.replace({ query })
      }
      if (this.rangeDate && this.rangeDate.includes('to')) {
        const dates = this.rangeDate.split(' to ')
        $and.push({ created_at: { $gte: this.$moment(dates[0]).toDate().getTime(), $lte: this.$moment(dates[1]).toDate().getTime() } })
        query = { ...query, created_at: this.rangeDate }
      }
      if (this.dispatchType && this.dispatchType !== '') {
        if (this.dispatchType === 'Non Dispatché') {
          $and.push({ 'system.items.dispatch': { $in: ['Non Dispatché', null] } })
        } else {
          $and.push({ 'system.items.dispatch': this.dispatchType })
        }
        query = { ...query, dispatchType: this.dispatchType }
        this.$router.replace({ query })
      }
      if (this.logisticType && this.logisticType !== '') {
        $and.push({ 'system.items.logistic': this.logisticType })
        query = { ...query, logisticType: this.logisticType }
        this.$router.replace({ query })
      }
      if (this.shipperType && this.shipperType !== '') {
        $and.push({ 'system.items.shipper': this.shipperType })
        query = { ...query, shipperType: this.shipperType }
        this.$router.replace({ query })
      }
      if ($and.length) {
        filter.$and = $and
      }
      this.$router.replace({ query })
      this.$emit('change', filter, this.searchQuery)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
